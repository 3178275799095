<template>
  <div>
    <v-container fluid class="py-8">
      <Notify ref="myNotify" :details="message"></Notify>
      <v-row>
        <v-col cols="12">
          <v-col cols="12">
            <v-card color="light">
              <v-app-bar color="light" class="border-left-primary">
                <v-toolbar-title>{{ $t("slider.Sliders") }}</v-toolbar-title>
                <button
                  v-if="
                    $store.getters['auth/str_per'].indexOf(
                      'cms-slider-create'
                    ) > -1
                  "
                  class="py-5 mb-0 ms-auto"
                  @click="add_slider()"
                >
                  <v-icon class="custome-btn btn-primary">fas fa-plus</v-icon>
                </button>
              </v-app-bar>
              <Table
                :loading="loading"
                @action="action"
                :btns="btns"
                :headers="header"
                :items="sliders"
              ></Table>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
      <Modal :data="modal_data"></Modal>
    </v-container>
  </div>
</template>
<script>
import Table from "../Components/Table.vue";
import Notify from "../Components/New/Notify.vue";
import Modal from "../Components/New/Modal.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("cms");
export default {
  name: "Slider-Page",
  components: {
    Table,
    Notify,
    Modal,
  },
  data() {
    return {
      modal_data: {
        size: "500px",
        title: "",
      },
      loading: true,
      image: null,
      message: {
        msg: null,
        type: null,
      },
      btns: [
        {
          type: "icon",
          text: "edit",
          color: "bg-gradient-success",
          icon: "mdi-pencil",
          permission: "cms-slider-update",
        },
        {
          type: "icon",
          text: "destroy",
          color: "bg-gradient-danger",
          icon: "mdi-delete",
          permission: "cms-slider-delete",
        },
      ],
      header: [
        { text: this.$i18n.t("image"), value: "image", align: "center" },
        { text: this.$i18n.t("cycle.title"), value: "title", align: "center" },
        {
          text: this.$i18n.t("general.Action"),
          value: "btns",
          align: "center",
        },
      ],
      form_data: {
        title: "",
        image: null,
      },
      form_style: [
        {
          col: "12",
          type: "text",
          label: this.$i18n.t("cycle.title"),
          error: null,
          value_text: "title",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "12",
          action: true,
          type: "file",
          image: true,
          accept: "image/*",
          label: this.$i18n.t("image"),
          error: null,
          value_text: "image",
          type_file: "single",
          rules: [
            (value) =>
              !value ||
              value.size < 2000000 ||
              this.$i18n.t("form.file size should be less than 2 MB"),
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState(["sliders"]),
  },
  methods: {
    ...mapActions(["getSliders", "destroySlider"]),
    get_sliders() {
      this.getSliders().then(
        () => {
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.$store.dispatch("form/setNotify", {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          });
        }
      );
    },
    action(id, name, item) {
      if (name == "edit") {
        this.edit_slider(item);
      } else if (name == "destroy") {
        this.destroy_slider(id);
      }
    },
    add_slider() {
      this.$store.commit("form/SET_DIALOG", true);
      this.modal_data.title = this.$i18n.t("slider.New Slider");
      this.$store.dispatch("form/setFormData", this.form_data);
      this.$store.dispatch("form/setData", this.form_style);
      this.$store.commit("form/INFO_CALL", {
        name: "cms/addSlider",
      });
    },
    edit_slider(item) {
      this.$store.commit("form/SET_DIALOG", true);
      this.modal_data.title =
        this.$i18n.t("slider.Edit Slider") + " # " + item.title;
      this.$store.dispatch("form/setData", this.form_style);
      this.form_data.title = item.title;
      this.$store.dispatch("form/setFormData", this.form_data);
      this.$store.commit("form/INFO_CALL", {
        name: "cms/editSlider",
        id: item.id,
      });
    },
    destroy_slider(id) {
      this.$swal({
        title: this.$i18n.t("general.Are you sure?!"),
        text: this.$i18n.t("You won't be able to revert this!"),
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: this.$i18n.t("general.Yes, delete it!"),
        cancelButtonText: this.$i18n.t("general.No, cancel!"),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.destroySlider(id).then(
            (response) => {
              // console.log(response)
              this.$swal.fire(
                this.$i18n.t("general.Deleted!"),
                response.data.message,
                "success"
              );
            },
            (error) => {
              console.log(error);
              this.$swal.fire(
                this.$i18n.t("general.Error"),
                this.$i18n.t("general.There error please try again"),
                "error"
              );
            }
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire(
            this.$i18n.t("general.Cancelled"),
            this.$i18n.t("general.Cancelled Delete"),
            "error"
          );
        }
      });
    },
  },
  mounted() {
    this.get_sliders();
    document.title = this.$i18n.t("slider.Sliders");
  },
};
</script>
